import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from "prop-types"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div className="site">
      <Header />
      
      <main className="site-content">
        <div>
          { children }
        </div>
      </main>      
    
    <Footer />  
    </div>
  )
}

export default Layout
