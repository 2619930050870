import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import NavBar from './NavBar'

import TRGLogo from '../../images/TRCLogo'

const Header = ({ siteTitle }) => (
  <header className="siteHeader">
    <NavBar />
    
  </header>
)


export default Header
